import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogCatsService {

  constructor(private http:HttpClient) { }
  list(){
    return this.http.get(`${environment.endpoint}/blogs-categories`)
  }
  update(service:any){
    console.log(service)
    const form:FormData = new FormData();
    for (const [key, value] of Object.entries(service)) {
      if(value!=null)
        if(key==`image`){
          form.append(`image`,service.image)
        }
        else{
          form.append(key,`${value}`)
        }

    }

    return this.http.post(`${environment.endpoint}/blog/category/update`,form)
  }
  add(service:any){
    console.log(service)
    const form:FormData = new FormData();
    for (const [key, value] of Object.entries(service)) {
      if(value!=null)
        if(key==`image`){
          form.append(`image`,service.image)
        }
        else{
          form.append(key,`${value}`)
        }

    }

    return this.http.post(`${environment.endpoint}/blog/category/create`,form)
  }
  delBlog_cat(service:any){
    return this.http.delete(`${environment.endpoint}/blog/category/delete?category_id=${service}`)
  }
}
