import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JobsRoutingModule } from './jobs-routing.module';
import { HomeComponent } from './home/home.component';
import { CreateComponent } from './create/create.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EditComponent } from './edit/edit.component';
import { QuillModule } from 'ngx-quill';
import { ShowCvComponent } from './show-cv/show-cv.component';


@NgModule({
  declarations: [HomeComponent, CreateComponent, EditComponent, ShowCvComponent],
  imports: [
    CommonModule,
    JobsRoutingModule,
    HttpClientModule,
ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    QuillModule.forRoot(),
  ]
})
export class JobsModule { }
