import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { BlogCatsService } from '../blog-cats.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {
  public service_id = this.route.snapshot.paramMap.get('id');
  change: boolean=false;
;  base_url = environment.baseUrl;
  selectedService = null;
  public submitted = false;
  public image:File = null;
  public base_64_image:any;
  public form:FormGroup;
  constructor(private route: ActivatedRoute,private router: Router,private formbuilder:FormBuilder,private service:BlogCatsService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.form = this.formbuilder.group({
      name_EN:['',Validators.required],
      name_AR:['',Validators.required],



    })
    this.getService()
  }
  getService(){
    const list= JSON.parse(localStorage.getItem('blog_cats'));
    console.log(list);
this.selectedService=list.find((item :any )=> item.id==this.service_id);



      this.displayInData(this.form.controls,this.selectedService)





  }

  displayInData(formControls,keys){
    const requiredControls = Object.entries(formControls).filter((control:any)=>control[1].errors != null).map(control=>control[0]);
    for (const [key, value] of Object.entries(keys)) {
      (requiredControls.includes(key) ? formControls[key].setValue(value) : false);


    }

  }
  get f() { return this.form.controls }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) { return }
    this.spinner.show()
    const form = {
      name_ar:this.f.name_AR.value,
      name_en:this.f.name_EN.value,
      category_id:this.service_id

    }
    this.service.update(form).subscribe((response:any)=>{
      this.spinner.hide()
      Swal.fire(
        `نجاح`,
        `تم تعديل التصنيف `,
        `success`
      )
      this.router.navigate(["/app/blog_cats/list"])
    })
  }

}
