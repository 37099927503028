import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateComponent } from './create/create.component';


import { ListComponent } from './list/list.component';
import { UpdateComponent } from './update/update.component';


const routes: Routes = [
  {path:'',component:CreateComponent, data: { title: 'إضافة تصنيف جديدة' }},
  {path:'create',component:CreateComponent, data: { title: 'إضافة تصنيف جديدة' }},
  {path:'list',component:ListComponent, data: { title: 'عرض تصنيفات المدونة' }},
  {path:'update/:id',component:UpdateComponent, data: { title: 'تعديل التصنيف ' }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BlogCatsRoutingModule { }
