import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlogsRoutingModule } from './blogs-routing.module';
import { ListComponent } from './list/list.component';
import { CreateComponent } from './create/create.component';
import { UpdateComponent } from './update/update.component';
import { HttpClientModule } from '@angular/common/http';
import { QuillModule } from 'ngx-quill';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { DetailsBlogComponent } from './details-blog/details-blog.component';


@NgModule({
  declarations: [ListComponent, CreateComponent, UpdateComponent, DetailsBlogComponent],
  imports: [
    CommonModule,
    BlogsRoutingModule,
    HttpClientModule,
    QuillModule.forRoot(),
    FormsModule,
    MatFormFieldModule,
    MatDialogModule,
    ReactiveFormsModule
  ]
})
export class BlogsModule { }
