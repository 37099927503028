import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ServicesService } from '../../services/services.service';
import { Router } from '@angular/router';
import { JosService } from '../jos.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  public form:FormGroup;
  public submitted = false;
  JobsList: any;
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['code-block'],
        // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction

        // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6] }],
        ['link', 'image', 'video'],
        [{ font: [] }],
        [{ align: [] }],

        ['clean'], // remove formatting button
      ],
    },
  };

summary: any='';
Responsibilitie: any='';
Qualifications: any='';
skill: any='';
  constructor(private router: Router,private formbuilder:FormBuilder,private service:JosService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.form = this.formbuilder.group({

      title:['',Validators.required],
      type:['',Validators.required],
      category_id:['',Validators.required],
      link:['',Validators.required],
      location:['',Validators.required],

    })
    this.showAll()
  }
showAll(){
  this.spinner.show()
  this.service.list().subscribe((data:any) => {
    console.log(data);

    this.JobsList = data.data;

    this.spinner.hide()
  });
}

  get f() { return this.form.controls }
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) { return }
    this.spinner.show()
    const form = {...this.form.value , summary: this.summary , qualifications:this.Qualifications , responsibilities:this.Responsibilitie, skills:this.skill}
    console.log(form);

    this.service.AddJobs(form).subscribe((response:any)=>{
      this.spinner.hide()
      Swal.fire(
        `نجاح`,
        `تم إضافة الوظيفة`,
        `success`
      )
      this.router.navigate(["/app/jobs/list"])
    })
  }
}
