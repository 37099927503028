import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JosService } from '../jos.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-show-cv',
  templateUrl: './show-cv.component.html',
  styleUrls: ['./show-cv.component.scss']
})
export class ShowCvComponent implements OnInit {
  public faq_id = this.route.snapshot.paramMap.get('id');
  JobsCvList: any;
  base_url = environment.baseUrl;
  constructor(private route:ActivatedRoute ,private faq:JosService , private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.showJobsCV()
  }
  showJobsCV(){
    this.spinner.show()


    this.faq.getJobsCV(this.faq_id).subscribe((data:any) => {
      console.log(data);

      this.JobsCvList = data.data;

      this.spinner.hide()
    });
  }
}
