import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-details-blog',
  templateUrl: './details-blog.component.html',
  styleUrls: ['./details-blog.component.scss']
})
export class DetailsBlogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    console.log(data?.content);

  }

  ngOnInit(): void {
  }

}
