import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogCatsService } from '../blog-cats.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  public form:FormGroup;
  submitted: boolean=false;
  constructor(private route: ActivatedRoute,private router: Router,private formbuilder:FormBuilder,private service:BlogCatsService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.form = this.formbuilder.group({
      name_EN:['',Validators.required],
      name_AR:['',Validators.required],



    })

}
get f() { return this.form.controls }

onSubmit() {
  this.submitted = true;
  if (this.form.invalid) { return }
  this.spinner.show()
  const form = {
    name_ar:this.f.name_AR.value,
    name_en:this.f.name_EN.value,


  }
  this.service.add(form).subscribe((response:any)=>{
    this.spinner.hide()
    Swal.fire(
      `نجاح`,
      `تم أضافة التصنيف `,
      `success`
    )
    this.router.navigate(["/app/blog_cats/list"])
  })
}

}
