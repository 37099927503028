import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListService {

  constructor(private http:HttpClient) { }
  list(){
    return this.http.get(`${environment.endpoint}/blogs`)
  }
  list_cats(){
    return this.http.get(`${environment.endpoint}/blogs-categories`)
  }
  create(service:any):any {
    const formData:FormData = new FormData();
    for (const [key, value] of Object.entries(service)) {
      if(key==`image`){
        formData.append(`image`,service.image)
      }
     else{
        formData.append(key,`${value}`)
      }
    }
    return this.http.post(`${environment.endpoint}/blog/create`,formData)
  }
  update(service:any):any {
    const formData:FormData = new FormData();
    for (const [key, value] of Object.entries(service)) {
      if(value!=null){
        if(key==`image`){
          formData.append(`image`,service.image)
        }
       else{
          formData.append(key,`${value}`)
        }
      }

    }
    return this.http.post(`${environment.endpoint}/blog/update`,formData)
  }
  delFaq(service:any){
    return this.http.delete(`${environment.endpoint}/blog/delete?blog_id=${service}`)
  }
}
