import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ListService } from '../list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  item:any=''
  question:any=''
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['code-block'],
        // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction

        // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6] }],
        ['link', 'image', 'video'],
        [{ font: [] }],
        [{ align: [] }],

        ['clean'], // remove formatting button
      ],
    },
  };

htmlText: any='';
submitted: boolean=false;
  bolgs_cats: any;
  form:FormGroup
  public image:any = null;
  public base_64_image:any;
  public faq_id = this.route.snapshot.paramMap.get('id');
  base_url = environment.baseUrl;
  constructor(private route:ActivatedRoute,private router:Router, private spinner: NgxSpinnerService,private faq:ListService ,private formbuilder:FormBuilder) { }
  detectImage(event) {
    const file = event.target.files[0];
    this.image = file
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        this.base_64_image = reader.result;
    };
  }
  ngOnInit(): void {
    this.showAll()
    const list= JSON.parse(localStorage.getItem('blogs'));
    console.log(list);
this.item=list.find((item :any )=> item.id==this.faq_id);
console.log(this.item);
this.base_64_image=this.base_url+'/'+this.item.image

    this.form = this.formbuilder.group({

      title:[this.item.title,Validators.required],
      summary:[this.item.summary,Validators.required],

      category_id:[this.item.category_id,Validators.required],

    })
this.htmlText=this.item.content
  }
  get f() { return this.form.controls }
  showAll(){
    this.spinner.show()
    this.faq.list_cats().subscribe((data:any) => {
      console.log(data);

      this.bolgs_cats = data.data;

      console.log(data);
      this.spinner.hide()
    });}
add(){
  this.submitted=true;
  if(this.form.invalid || (this.htmlText=='' || this.htmlText==null)){
    return;
  }
  const form = {...this.form.value,image:this.image , content:this.htmlText ,blog_id:this.item.id}
  console.log(form);
  this.spinner.show()
  this.faq.update(form).subscribe((response:any)=>{
    console.log(response)
    this.spinner.hide()
    Swal.fire(
      `نجاح`,
      `تم تعديل المفال`,
      `success`
    )
    this.router.navigate(["/app/blogs/list"])
    // window.location.reload()
  })
}

}
