import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateComponent } from './create/create.component';
import { HomeComponent } from './home/home.component';
import { EditComponent } from './edit/edit.component';
import { ShowCvComponent } from './show-cv/show-cv.component';



const routes: Routes = [
  {path:'',component:CreateComponent, data: { title: 'إضافة وظيفة جديدة' }},
  {path:'create',component:CreateComponent, data: { title: 'إضافة وظيفة جديدة' }},
  {path:'list',component:HomeComponent, data: { title: 'عرض الوظائف' }},
  {path:'update/:id',component:EditComponent, data: { title: 'تعديل الوظيفة ' }},
  {path:'job_cv/:id',component:ShowCvComponent, data: { title: 'CVs الوظيفة ' }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JobsRoutingModule { }
