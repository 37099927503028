import { Component, OnInit } from '@angular/core';
import { BlogCatsService } from '../blog-cats.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  blog_cats: any;

  constructor(private define:BlogCatsService , private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.getServices()
  }
  getServices(){
    this.spinner.show()

    this.define.list().pipe(map(response=>response['data'])).subscribe(services=>{
      this.blog_cats = services
      localStorage.setItem('blog_cats', JSON.stringify(this.blog_cats))
      this.spinner.hide()

    })
  }
  delete(service_id){
    Swal.fire({
      title: `يرجي الانتاه`,
      text: `هل انت متأكد من حذف التصنيف !`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'نعم',
      cancelButtonText: 'لا'

    })
    .then((result) => {
      if (result.value) {
        return this.define.delBlog_cat(service_id).pipe().subscribe((response) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم الحذف بنجاح',
            showConfirmButton: false,
            timer: 1500
          })
          this.getServices()
        })}
      })
  }
}
