import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ListService } from '../list.service';
import { environment } from 'src/environments/environment';
import {MatDialog} from '@angular/material/dialog';
import { CreateComponent } from '../create/create.component';
import { DetailsBlogComponent } from '../details-blog/details-blog.component';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  faqList:any=[]
  nameLength=12;
  base_url = environment.baseUrl;
  constructor(private faq:ListService , private spinner: NgxSpinnerService,private dialog: MatDialog) { }


  openDialog(item:any) {
    const dialogRef = this.dialog.open(DetailsBlogComponent , {
      height:'100vh',
      data:{
       content:item?.content
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  ngOnInit(): void {
    this.showAll()
  }
showAll(){
  this.spinner.show()
  this.faq.list().subscribe((data:any) => {
    console.log(data);

    this.faqList = data.data;
    localStorage.setItem('blogs', JSON.stringify(this.faqList))
    this.spinner.hide()
  });
}
delete(service_id){
  Swal.fire({
    title: `يرجي الانتاه`,
    text: `هل انت متأكد من حذف المقال !`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'نعم',
    cancelButtonText: 'لا'

  })
  .then((result) => {
    if (result.value) {
      return this.faq.delFaq(service_id).pipe().subscribe((response) => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'تم الحذف بنجاح',
          showConfirmButton: false,
          timer: 1500
        })
        this.showAll()
      })}
    })
}

}

