import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JosService {

  constructor(private http:HttpClient) { }
  list(){
    return this.http.get(`${environment.endpoint}/jobs-categories`)
  }
  addCat(form){
    return this.http.post(`${environment.endpoint}/job/category/create` , form)
  }
  editCat(form){
    return this.http.post(`${environment.endpoint}/job/category/update` , form)
  }
  deleteCat(id){
    return this.http.delete(`${environment.endpoint}/job/category/delete?category_id=${id}`)
  }
  getJobs(id){
    return this.http.get(`${environment.endpoint}/jobs?category_id=${id}`)
  }
  AddJobs(from){
      const formData:FormData = new FormData();
      for (const [key, value] of Object.entries(from)) {
        if(value!=null){


            formData.append(key,`${value}`)

      }}
      return this.http.post(`${environment.endpoint}/job/create`,formData)
    }
    EditJobs(from){
      const formData:FormData = new FormData();
      for (const [key, value] of Object.entries(from)) {
        if(value!=null){


            formData.append(key,`${value}`)

      }}
      return this.http.post(`${environment.endpoint}/job/update`,formData)
    }
    delJobs(id){
      return this.http.delete(`${environment.endpoint}/job/delete?job_id=${id}`)
    }
    getJobsCV(id){
      return this.http.get(`${environment.endpoint}/job/cvs?job_id=${id}`)
    }
  // /job/delete?job_id=2
}
